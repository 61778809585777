import { Injectable } from '@angular/core';
import { WalletBasicInfo } from '../blockchain/blockchain.model';

const TOKEN = 'token';
const WALLET_INFO = 'wallet-info';
const USERNAME = 'username';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  public setToken(value: string): void {
    sessionStorage.setItem(TOKEN, value);
  }

  public getToken(): string | null {
    return sessionStorage.getItem(TOKEN);
  }

  public setUsername(value: string): void {
    sessionStorage.setItem(USERNAME, value);
  }

  public getWalletBasicInfo(): WalletBasicInfo | null {
    const walletInfo = sessionStorage.getItem(WALLET_INFO);
    if (walletInfo) {
      return JSON.parse(walletInfo) as WalletBasicInfo;
    }
    return null;
  }

  public setWalletBasicInfo(walletBasicInfo: WalletBasicInfo | null): void {
    sessionStorage.setItem(WALLET_INFO, JSON.stringify(walletBasicInfo));
  }

  public getUsername(): string | null {
    return sessionStorage.getItem(USERNAME);
  }

  public set(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  public get(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}
