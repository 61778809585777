import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class ModalService {
  private modals: any[] = [];

  public add(modal: any) {
    this.modals.push(modal);
  }

  public remove(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  public open(id: string) {
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    modal.open();
  }

  public close(id: string) {
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    modal.close();
  }
}
